import { render, staticRenderFns } from "./Drawer.vue?vue&type=template&id=0ed3bc84&scoped=true"
import script from "./Drawer.vue?vue&type=script&lang=js"
export * from "./Drawer.vue?vue&type=script&lang=js"
import style0 from "./Drawer.vue?vue&type=style&index=0&id=0ed3bc84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ed3bc84",
  null
  
)

export default component.exports
<template>
  <div class="timeline-item">
    <div class="item-tamplate">
      <div class="dot" style="transform: translateY(4px)">
        <div v-if="$slots.dot">
          <slot name="dot"></slot>
        </div>
        <div v-else class="dot-slot"></div>
        <div class="line" v-if="!hide"></div>
      </div>
      <div class="item-content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // hide: false,
    }
  },
  inject: ['timeline'],
  computed: {
    hide() {
      return this.timeline.$slots['default'].length === this.$parent.$children.length
    },
  },
  created() {},
}
</script>

<style lang="scss">
.timeline-item {
  padding-top: 16px 0;
  .item-tamplate {
    display: flex;
    .dot {
      width: 22px;
      display: flex;
      flex-direction: column;
      transform: translateY(5px);

      .dot-slot {
        height: 4px;
        width: 4px;
        background: #1890ff;
        border: 4px solid #b9ddff;
        border-radius: 100%;
        box-sizing: content-box;
        transform: translateX(6px);
      }
      .line {
        flex: auto;
        box-sizing: border-box;
        border-left: 2px solid #e4e7ed;
        transform: translateX(10px);
      }
    }
    .item-content {
      flex: auto;
      line-height: 24px;
      padding: 0 20px 0 0;
    }
  }
}
</style>

<template>
  <section class="summary__wrapper">
    <div style="margin:10px 0;">
      <span class="top__title">{{ $t('com.report.reportV3.text043') }}</span>
    </div>
    <div class="row ise" v-if="interviewResult.haveVideoQuestion === 1">
      <div class="flexrow" v-if="aiData.isShowAI !== 2">
        <div class="lsiwu">{{ !showScoreLevel?$t('com.report.reportV3.text130_1') : aiData.isL5Position === 1 ? $t('com.report.reportV3.text130') : $t('com.report.reportV3.text003') }}</div>
        <van-rate v-if="!aiMarkCfg.singleStatus" :value="5" :size="14" disabled />
        <template v-else-if="aiData.isL5Position === 1 && aiData.l5Star > 0">
          <van-rate :value="(aiMarkCfg.allStatus && aiData.l5Star) || 0" :size="16" color="#ffd21e" void-icon="star" void-color="#eee" :gutter="2" readonly />
          <template v-if="showStarExplain">
            <span class="huase" v-if="aiData.l5Star === 1">{{ $t('com.report.base.wqbsr') }}</span>
            <span class="huase" v-if="aiData.l5Star === 2">{{ $t('com.report.base.bushengren') }}</span>
            <span class="huase" v-if="aiData.l5Star === 3">{{ $t('com.report.base.shengren') }}</span>
            <span class="huase" v-if="aiData.l5Star === 4">{{ $t('com.report.base.youxiu') }}</span>
            <span class="huase" v-if="aiData.l5Star === 5">{{ $t('com.report.base.chaochuqiwang') }}</span>
          </template>
        </template>
        <template v-else-if="aiData.isL5Position === 1 && aiData.tdnaScore === -1">{{ $t('com.report.reportV3.text004') }}</template>
        <template v-else-if="aiData.aiAllScore && aiData.aiAllScore > 0 && aiData.isShowAI && aiData.isAiModel > 0">
          <van-rate :value="(aiMarkCfg.allStatus && aiData.aiAllScore) || 0" :size="16" color="#ffd21e" void-icon="star" void-color="#eee" :gutter="2" readonly />
          <template v-if="showStarExplain">
            <span class="huase" v-if="aiData.aiAllScore === 1">{{ $t('com.report.base.wqbsr') }}</span>
            <span class="huase" v-if="aiData.aiAllScore === 2">{{ $t('com.report.base.bushengren') }}</span>
            <span class="huase" v-if="aiData.aiAllScore === 3">{{ $t('com.report.base.shengren') }}</span>
            <span class="huase" v-if="aiData.aiAllScore === 4">{{ $t('com.report.base.youxiu') }}</span>
            <span class="huase" v-if="aiData.aiAllScore === 5">{{ $t('com.report.base.chaochuqiwang') }}</span>
          </template>
      </template>
        <template v-else>
          <div class="mode-img1" v-if="interviewResult.haveVideoQuestion === 1">
            <!-- <img src="@/assets/img/generating.png" v-if="aiData.isShowAI && aiData.isAiModel > 0" /> -->
            <div v-if="aiData.isShowAI && aiData.isAiModel > 0">{{ $t('com.report.reportV3.text004') }}</div>
            <!-- <img src="@/assets/img/dingzhi-mode.png" v-else /> -->
            <div v-else>{{ $t('com.report.reportV3.text005') }}</div>
          </div>
          <van-rate v-else :value="0" :size="16" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
        </template>
      </div>
      <!--<div v-if="aiData.isShowAI !== 2">
        <van-rate v-if="isOpenL2 && l2score > -1" :value="(aiMarkCfg.allStatus && l2score) || 0" :size="14" color="#ffd21e" void-icon="star" void-color="#eee" :gutter="2" readonly />
        <template v-else>
          <div class="mode-img1" v-if="interviewResult.haveVideoQuestion === 1">
            <!~~ <img src="@/assets/img/generating.png" v-if="aiData.isShowAI && aiData.isAiModel > 0" /> ~~>
            <div v-if="l2status">{{ $t('com.report.reportV3.text004') }}</div>
            <!~~ <img src="@/assets/img/dingzhi-mode.png" v-else /> ~~>
            <div v-else>-</div>
          </div>
          <van-rate v-else :value="0" :size="14" color="#ffd21e" void-icon="star" void-color="#eee" readonly />
        </template>

        <div>
          <span v-if="aiData.isL5Position === 1">{{ $t('com.report.reportV3.text006') }}</span>
          <span v-else>
            {{ isAnyongCompany ? $t('com.report.base.english') : $t('com.report.reportV3.text006') }}
          </span>
        </div>
      </div>
      <div v-if="aiData.haveise && isescoreCN">
        <van-rate :value="isescoreCN ? isescoreCN.iseAllScore : 0" :color="isescoreCN && isescoreCN.iseAllScore > 0 ? '#ffd21e' : ''" void-icon="star" void-color="#eee" :size="14" :gutter="2" readonly allow-half />
        <div>{{ $t('com.report.reportV3.text041') }}</div>
      </div>

      <div v-if="aiData.haveise && isescoreEN">
        <van-rate :value="isescoreEN ? isescoreEN.iseAllScore : 0" :color="isescoreEN && isescoreEN.iseAllScore > 0 ? '#ffd21e' : ''" void-icon="star" void-color="#eee" :size="14" :gutter="2" readonly allow-half />
        <div>{{ $t('com.report.reportV3.text042') }}</div>
      </div>

      <div>
        <van-rate :value="(aiMarkCfg.manualStatus && aiData.estimateAllScore) || 0" :size="14" color="#ffd21e" void-icon="star" void-color="#eee" :gutter="2" readonly />
        <div>{{ $t('com.report.reportV3.text043') }}</div>
      </div>
      <div>
        <van-rate v-if="aiData.estimateVideoScore && aiData.estimateVideoScore > 0" :value="(aiMarkCfg.manualStatus && aiData.estimateVideoScore) || 0" color="#ffd21e" void-icon="star" void-color="#eee" :size="14" :gutter="2" readonly allow-half />
        <van-rate v-else :value="(aiMarkCfg.manualStatus && aiData.estimateTotalScore) || 0" color="#ffd21e" void-icon="star" void-color="#eee" :size="14" :gutter="2" readonly allow-half />
        <!~~ <van-rate v-model="aiData.estimateVideoScore" :size="14" color="#ffd21e" :gutter="2" readonly /> ~~>
        <div>{{ $t('com.report.reportV3.text044') }}</div>
      </div>
      <!~~ </div>
    <div class="row2"> ~~>
      <div class="lable-item">
        <div>{{ $t('com.report.reportV3.text045', [aiData.illegalCount]) }}</div>
        <div>{{ $t('com.report.reportV3.text046') }}</div>
      </div>-->

      <!-- <div @click="isShowScoreList = !isShowScoreList" v-if="showLabelGroupScoreList" class="lable-item">
				<div class="top-lable">
					<span class="number">{{ totalScore }}</span>
					<span class="score">分</span>
					<van-icon :class="isShowScoreList ? 'active_icon' : ''" name="play" />
				</div>
				<div>标签总分</div>
			</div>
			<div v-for="(label, index) in groupList" :key="index" class="lable-item">
				<div>
					<span class="number"> {{ label.value }}</span> <span class="score">分</span>
				</div>
				<div>{{ label.label }}</div>
			</div> -->
    </div>

    <div class="xingx" v-if="interviewResult.haveVideoQuestion === 1 && showStarExplain">
      <span>{{ $t('com.report.reportV3.yxexsx') }}</span>
    </div>
    <div class="progres" v-show="showPercent && isL5Position">
      <span class="txt">
        {{ $t('com.report.reportV3.text139') }}
        <span class="num">{{ sort }}%</span>
        {{ $t('com.report.reportV3.text140') }}
      </span>
      <el-progress :percentage="sort" color="#C8F3F4" define-back-color="#F6FAFC" :show-text="false"></el-progress>
    </div>
    <div class="row ise" v-if="interviewResult.haveVideoQuestion === 1 && aiData.isShowAI !== 2 && (isXCpositon === 1 || isXCpositon === 3)">
      <div class="flexrow">
        <div class="lsiwu">{{ $t('com.report.base.text100_1') }}:</div>
        <van-rate v-if="!aiMarkCfg.singleStatus" :value="5" :size="14" disabled />
        <template v-else-if="aiData.isL5Position === 1 && languageScore.yueStar > 0">
          <van-rate :value="(aiMarkCfg.allStatus && languageScore.yueStar) || 0" :size="16" color="#ffd21e" void-icon="star" void-color="#eee" :gutter="2" readonly />
          <template v-if="showStarExplain">
            <span class="huase" v-if="languageScore.yueStar === 1">{{ $t('com.report.base.wqbsr') }}</span>
            <span class="huase" v-if="languageScore.yueStar === 2">{{ $t('com.report.base.bushengren') }}</span>
            <span class="huase" v-if="languageScore.yueStar === 3">{{ $t('com.report.base.shengren') }}</span>
            <span class="huase" v-if="languageScore.yueStar === 4">{{ $t('com.report.base.youxiu') }}</span>
            <span class="huase" v-if="languageScore.yueStar === 5">{{ $t('com.report.base.chaochuqiwang') }}</span>
          </template>
        </template>
        <template v-else>{{ $t('com.report.reportV3.text004') }}</template>
      </div>
    </div>
    <div class="row ise" v-if="interviewResult.haveVideoQuestion === 1 && aiData.isShowAI !== 2 && (isXCpositon === 1 || isXCpositon === 2)">
      <div class="flexrow">
        <div class="lsiwu">{{ $t('com.report.base.text100_2') }}:</div>
        <van-rate v-if="!aiMarkCfg.singleStatus" :value="5" :size="14" disabled />
        <template v-else-if="aiData.isL5Position === 1 && languageScore.enStar > 0">
          <van-rate :value="(aiMarkCfg.allStatus && languageScore.enStar) || 0" :size="16" color="#ffd21e" void-icon="star" void-color="#eee" :gutter="2" readonly />
          <template v-if="showStarExplain">
            <span class="huase" v-if="languageScore.enStar === 1">{{ $t('com.report.base.wqbsr') }}</span>
            <span class="huase" v-if="languageScore.enStar === 2">{{ $t('com.report.base.bushengren') }}</span>
            <span class="huase" v-if="languageScore.enStar === 3">{{ $t('com.report.base.shengren') }}</span>
            <span class="huase" v-if="languageScore.enStar === 4">{{ $t('com.report.base.youxiu') }}</span>
            <span class="huase" v-if="languageScore.enStar === 5">{{ $t('com.report.base.chaochuqiwang') }}</span>
          </template>
        </template>
        <template v-else>{{ $t('com.report.reportV3.text004') }}</template>
      </div>
    </div>

    <div class="data__wrapper" v-if="aiData.haveise && isescoreCN">
      <div class="data__title">{{ $t('com.report.reportV3.text141') }}</div>
      <van-rate
        :value="isescoreCN ? isescoreCN.iseAllScore : 0"
        :color="isescoreCN && isescoreCN.iseAllScore > 0 ? '#ffd21e' : ''"
        void-icon="star"
        void-color="#eee"
        :size="14"
        :gutter="2"
        readonly
        allow-half
      />
      <div class="data__score">
        {{ $t('com.report.reportV3.text143', [(aiData && isescoreCN && +isescoreCN.iseAllScore) || 0]) }}
      </div>
    </div>
    <div class="data__wrapper" v-if="aiData.haveise && isescoreEN">
      <div class="data__title">{{ $t('com.report.reportV3.text142') }}</div>
      <van-rate
        :value="isescoreEN ? isescoreEN.iseAllScore : 0"
        :color="isescoreEN && isescoreEN.iseAllScore > 0 ? '#ffd21e' : ''"
        void-icon="star"
        void-color="#eee"
        :size="14"
        :gutter="2"
        readonly
        allow-half
      />
      <div class="data__score">
        {{ $t('com.report.reportV3.text143', [(aiData && isescoreEN && +isescoreEN.iseAllScore) || 0]) }}
      </div>
    </div>

    <div class="tattooResults" v-if="hasVideoQues12">
      <div v-for="(item,index) in tattooResultsArr" :key="index" v-if="item.result !=2" style="margin-top: 10px;">
        <div style="font-weight: 500">{{ item.name }}：</div><span style="color: #FFCA3A;">
          {{ item.result == -1?$t('com.report.reportV3.text004'):
           item.result == 0?$t('com.report.onlinetest.text035'):
           $t('com.report.onlinetest.text036') }}
        </span>
      </div>
    </div>

    <div class="interview_time">
      <div class="use_time" @click="changeIconStatus">
        <div>
          <img class="clockimg" src="@/assets/clock.png" alt="" />
          {{ $t('com.report.reportV3.text047') }}：{{ interviewResult.usetime }}
        </div>
        <!-- <div class="trans_icon"><van-icon :class="isActive ? 'active_icon' : ''" name="play" /></div> -->
      </div>
      <!-- <van-transition name="fade-down"> -->
      <div class="time" v-show="isActive">
        <div class="begin_time" v-if="interviewResult.real_begininterview_time">
          <!-- <span v-if="interviewResult.real_begininterview_time || interviewResult.interview_finish_time">(</span> -->
          <span>{{ $t('com.report.reportV3.text048') }}：{{ moment(+interviewResult.real_begininterview_time).format('YYYY-MM-DD HH:mm:ss') }} </span>
        </div>
        <div class="begin_time" v-if="interviewResult.interview_finish_time">
          <span>{{ $t('com.report.reportV3.text049') }}：{{ moment(+interviewResult.interview_finish_time).format('YYYY-MM-DD HH:mm:ss') }} </span>
          <!-- <span v-if="interviewResult.real_begininterview_time || interviewResult.interview_finish_time">)</span> -->
        </div>
        <!-- <div class="begin_time"><span>初次进入时间：</span>{{ interviewResult.real_begininterview_time ? moment(+interviewResult.real_begininterview_time).format('YYYY-MM-DD HH:mm:ss') : '-' }}</div>
        <div class="begin_time"><span>完成面试时间：</span>{{ interviewResult.interview_finish_time ? moment(+interviewResult.interview_finish_time).format('YYYY-MM-DD HH:mm:ss') : '-' }}</div> -->
      </div>
      <!-- </van-transition> -->
    </div>

    <template v-if="isShowEnglishability">
    
    <div v-if="enSocre.totalStar && enSocre.totalStar >= 1">
      <span class="top__title">{{ $t('com.report.reportV3.text155') }}</span>
    </div>
    <div class="row ise" v-if="enSocre.totalStar && enSocre.totalStar >= 1">

      <div class="flexrow">
        <div class="lsiwu" style="margin-right: 0;">{{ $t('com.report.reportV3.text156') }}</div>
        <!-- <van-rate v-if="!true" :value="5" :size="14" disabled /> -->
        <template v-if="enSocre && enSocre.totalStar > 0">
          <van-rate :value="(enSocre && enSocre.totalStar) || 0" :size="16" color="#ffd21e" void-icon="star" void-color="#eee" :gutter="2" readonly />
          <span class="huase">{{$t('com.report.reportV3.text059', [enSocre.totalStar]) }}</span>
        </template>
        <template v-else>{{ $t('com.report.reportV3.text004') }}</template>
      </div>
    </div>
    <div class="xingx" v-if="enSocre.totalStar && enSocre.totalStar >= 1">
      <span v-if="enSocre.totalStar == 1">{{ $t('com.report.reportV3.text157') }}</span>
      <span v-if="enSocre.totalStar == 2">{{ $t('com.report.reportV3.text158') }}</span>
      <span v-if="enSocre.totalStar == 3">{{ $t('com.report.reportV3.text159') }}</span>
      <span v-if="enSocre.totalStar == 4">{{ $t('com.report.reportV3.text160') }}</span>
      <span v-if="enSocre.totalStar == 5">{{ $t('com.report.reportV3.text161') }}</span>
    </div>
    <div class="enData_summary" v-if="enSocre.totalStar && enSocre.totalStar >= 1">
      <div class="enData_left">
        <div class="enData_left__score">
          <div class="enData_left__Level">Level</div>
          <div class="enData_left__content">
            <div class="enData__line">
              <span style="flex:3;"></span>
              <span style="flex:3;"></span>
              <span style="flex:2;"></span>
              <span style="flex:1;"></span>
              <span style="flex:1;"></span>
              <span style="flex:1;"></span>
            </div>
            <div class="left1">
              <span style="flex:3;">{{ $t('com.report.reportV3.text162') }}</span>
              <span style="flex:3;">{{ $t('com.report.reportV3.text163') }}</span>
              <span style="width: 42px;">{{ $t('com.report.reportV3.text164') }}</span>
              <span style="width: 32px;">{{ $t('com.report.reportV3.text165') }}</span>
              <span style="flex:1;">{{ $t('com.report.reportV3.text166') }}</span>
              <span style="width: 16px;"> </span>
            </div>
            <div class="left2">
              <div class="enData__progress" :style="{width:(Number(enSocre.totalScore || 0)*2.4)/37.5 + 'rem'}"></div>
              <div class="enData__score">{{$t('com.report.reportV3.text143', [enSocre.totalScore])}}</div>
            </div>
            <div class="left1 left3">
              <span style="flex:3;">&nbsp; 0</span>
              <span style="flex:3;">30</span>
              <span style="flex:2;">60</span>
              <span style="flex:1;">80</span>
              <span style="flex:1;">90</span>
              <span style="flex:1;">100</span>
            </div>
          </div>
        </div>
        <div class="enData_left__tips">
          <h3>
            {{ $t('com.report.reportV3.text167') }}
          </h3>
          <div>
            {{ $t('com.report.reportV3.text168') }}
          </div>
        </div>
      </div>
      <div class="enData_right">
        <div class="enData_left__score">
          <div class="enData_left__Level">{{ $t('com.report.reportV3.text169') }}</div>
          <div class="enData_left__content">
            <div class="enData__line">
              <span style="flex:7;"></span>
              <span style="flex:2;"></span>
              <span style="flex:1;"></span>
              <span style="flex:1;"></span>
            </div>
            <div class="left1">
              <span style="flex:7;">{{ $t('com.report.reportV3.text175') }}</span>
              <span style="flex:2;">{{ $t('com.report.reportV3.text176') }}</span>
              <span style="flex:1;">{{ $t('com.report.reportV3.text177') }}</span>
              <span style="flex:1;"> </span>
            </div>
            <div class="left2">
              <div class="enData__label">{{ $t('com.report.reportV3.text170') }}</div>
              <div class="enData__progress" :style="{background:enColor(Number(enSocre.accuracyScore)), width:Number(enSocre.accuracyScore || 0)*2.26/37.5 + 'rem'}"></div>
              <div class="enData__score">{{$t('com.report.reportV3.text143', [enSocre.accuracyScore])}}</div>
            </div>
            <div class="left2">
              <div class="enData__label">{{ $t('com.report.reportV3.text171') }}</div>
              <div class="enData__progress" :style="{background:enColor(Number(enSocre.fluencyScore)), width:Number(enSocre.fluencyScore || 0)*2.26/37.5 + 'rem'}"></div>
              <div class="enData__score">{{$t('com.report.reportV3.text143', [enSocre.fluencyScore])}}</div>
            </div>
            <div class="left2">
              <div class="enData__label">{{ $t('com.report.reportV3.text172') }}</div>
              <div class="enData__progress" :style="{background:enColor(Number(enSocre.syntaxScore)), width:Number(enSocre.syntaxScore || 0)*2.26/37.5 + 'rem'}"></div>
              <div class="enData__score">{{$t('com.report.reportV3.text143', [enSocre.syntaxScore])}}</div>
            </div>
            <div class="left2">
              <div class="enData__label">{{ $t('com.report.reportV3.text173') }}</div>
              <div class="enData__progress" :style="{background:enColor(Number(enSocre.wordStockScore)), width:Number(enSocre.wordStockScore || 0)*2.26/37.5 + 'rem'}"></div>
              <div class="enData__score">{{$t('com.report.reportV3.text143', [enSocre.wordStockScore])}}</div>
            </div>
            <div class="left2">
              <div class="enData__label">{{ $t('com.report.reportV3.text174') }}</div>
              <div class="enData__progress" :style="{background:enColor(Number(enSocre.topicScore)), width:Number(enSocre.topicScore || 0)*2.26/37.5 + 'rem'}"></div>
              <div class="enData__score">{{$t('com.report.reportV3.text143', [enSocre.topicScore])}}</div>
            </div>

            
            <div class="left1 left3">
              <span style="flex:7;">&nbsp;0</span>
              <span style="flex:2;">70</span>
              <span style="flex:1;">90</span>
              <span style="flex:1;">100</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </template>
    <div v-if="(showL2Radar && !isL5Position) || (isL5Position && isPositiveInteger(l5Score))" class="l2-question">
      <template v-if="seriesData && seriesData.length && seriesData.length > 2 && highchartsData1.length > 2 && aiMarkCfg.allStatus">
        <span class="top__title">{{ $t('com.report.reportV3.LevelOfCompetency_1') }}</span>
        <div class="l2-q_r" id="scoreRadar"></div>
      </template>
    </div>
    <div class="sumupo" v-if="(aiData.sumContent || aiData.nextSuggest) && aiMarkCfg.allStatus">
      <div class="spcont" v-if="aiData.sumContent">
        <div class="spctit">{{ $t('com.report.reportV3.text151') }}</div>
        <div class="spcdtil">
          <span v-html="`${aiData.sumContent}`.replace(/\s+\n/g, '<div data-height3></div>').replace(/\n/g, '<div data-height3></div>')" />
        </div>
      </div>
      <!-- <el-divider v-if="aiData.sumContent && aiData.nextSuggest" /> -->
      <div class="spcont mt16" v-if="aiData.nextSuggest">
        <div class="spctit">{{ $t('com.report.reportV3.text148') }}</div>
        <div class="spcdtil">
          <span v-html="`${aiData.nextSuggest}`.replace(/\s+\n/g, '<br/>').replace(/\n/g, '<br/>')" />
        </div>
      </div>
    </div>
    <div class="tip" v-if="(aiData.sumContent || aiData.nextSuggest) && aiMarkCfg.allStatus">{{ $t('com.report.reportV3.text152') }}</div>
    <div v-if="false && isFinished && isL5Position && gradeExplain && gradeExplain.length" class="unscramble">
      <div class="ustitl">{{ $t('com.report.reportV3.text146') }}</div>
      <el-divider />
      <div v-for="(item, index) in gradeExplain" :key="index" class="usitm">
        <div class="usitmtop">
          <!-- <span class="usiptit">
            {{ `${item.assessmentPoint}（${item.calculateStar}${$t('hologram.report.star')}）` }}
          </span> -->
          <span class="usiptit">
            {{ `${item.assessmentPoint}（${$t('com.report.reportV3.text059', [item.calculateStar])}）` }}
          </span>
          <van-rate :value="item.calculateStar" :size="14" color="#ffd21e" void-icon="star" void-color="#eee" :gutter="2" :count="3" readonly />
          <span v-if="item.calculateStar">
            <span class="huase" v-if="item.calculateStar === 1">{{ $t('com.report.interviewRecord.text51') }}</span>
            <span class="huase" v-if="item.calculateStar === 2">{{ $t('com.report.interviewRecord.text52') }}</span>
            <span class="huase" v-if="item.calculateStar === 3">{{ $t('com.report.interviewRecord.text53') }}</span>
          </span>
        </div>
        <!-- <pre class="usitmbot" v-html="item.scoringCriteria" /> -->
        <div class="sumup">
          <div v-if="item.scoreReason" class="spcont">
            <div class="spctit">{{ $t('com.report.reportV3.text153') }}</div>
            <div class="spcdtil">
              <span v-html="`${item.scoreReason}`.replace(/\s+\n/g, '<br/>').replace(/\n/g, '<br/>')" />
            </div>
          </div>
          <template v-if="item.assessmentDesc">
            <el-divider v-if="item.scoreReason" />
            <div class="spcont">
              <div class="spctit">{{ $t('com.report.reportV3.text149') }}</div>
              <div class="spcdtil">{{ item.assessmentDesc }}</div>
            </div>
          </template>
          <template v-if="item.scoringCriteriaOne || item.scoringCriteriaTwo || item.scoringCriteriaThree">
            <el-divider v-if="item.scoreReason || item.assessmentDesc" />
            <div class="spcont">
              <div class="spctit">{{ $t('com.report.reportV3.text150') }}</div>
              <div>
                <div v-if="item.scoringCriteriaOne" class="spcdtil">
                  <img class="starimg_e" src="@/assets/star_one_en.png" alt="" v-if="langs === 'en'" />
                  <img class="starimg" src="@/assets/star_one.png" alt="" v-else />
                  <br />
                  <span v-html="`${item.scoringCriteriaOne}`.replace(/\s+\n/g, '<br/>').replace(/\n/g, '<br/>')" />
                </div>
                <div v-if="item.scoringCriteriaTwo" class="spcdtil">
                  <img class="starimg_e" src="@/assets/star_two_en.png" alt="" v-if="langs === 'en'" />
                  <img class="starimg" src="@/assets/star_two.png" alt="" v-else />
                  <br />
                  <span v-html="`${item.scoringCriteriaTwo}`.replace(/\s+\n/g, '<br/>').replace(/\n/g, '<br/>')" />
                </div>
                <div v-if="item.scoringCriteriaThree" class="spcdtil">
                  <img class="starimg_e" src="@/assets/star_three_en.png" alt="" v-if="langs === 'en'" />
                  <img class="starimg" src="@/assets/star_three.png" alt="" v-else />
                  <br />
                  <span v-html="`${item.scoringCriteriaThree}`.replace(/\s+\n/g, '<br/>').replace(/\n/g, '<br/>')" />
                </div>
              </div>
            </div>
          </template>
          <template v-if="item.isConfigSumContent === 1">
            <el-divider v-if="item.assessmentDesc || item.scoringCriteriaOne || item.scoringCriteriaTwo || item.scoringCriteriaThree" />
            <div class="spcont">
              <div class="spctit">{{ $t('com.report.reportV3.text147') }}</div>
              <div class="spcdtil" v-if="item.sumContent">{{ item.sumContent }}</div>
              <div class="hei30" v-loading="true" :element-loading-text="$t('com.report.reportV3.text004')" element-loading-spinner="el-icon-loading" v-else></div>
            </div>
          </template>
          <template v-if="item.isConfigNextSuggest === 1">
            <el-divider v-if="item.assessmentDesc || item.scoringCriteriaOne || item.scoringCriteriaTwo || item.scoringCriteriaThree || item.isConfigSumContent === 1" />
            <div class="spcont">
              <div class="spctit">{{ $t('com.report.reportV3.text148') }}</div>
              <div class="spcdtil" v-if="item.nextSuggest">{{ item.nextSuggest }}</div>
              <div class="hei30" v-loading="true" :element-loading-text="$t('com.report.reportV3.text004')" element-loading-spinner="el-icon-loading" v-else></div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import * as echarts from 'echarts'
import moment from 'moment'
import vue from 'vue'
import { Progress, Loading, Divider } from 'element-ui'
vue.use(Progress).use(Loading).use(Divider)
import i18n from '../../utils/i18n'
import { getLanguageScore, getEnglishability } from '@/api/mark'
export default {
  data() {
    return {
      totalScore: 0,
      showLabelGroupScoreList: false,
      labelGroupScoreList: [],
      isShowScoreList: false,
      moment: moment,
      isActive: true,
      languageScore: {},
      enSocre: {},
      answerInfos1: [
        { assessmentPoint: '团队合作', calculateStar: 3 },
        { assessmentPoint: '专业热忱', calculateStar: 2 },
        { assessmentPoint: '冠军心/要性冠军心/要性（销售）', calculateStar: 2 },
        { assessmentPoint: '稳定性（销售校招）', calculateStar: 3 },
        { assessmentPoint: '战略思维', calculateStar: 1 },
        { assessmentPoint: '创新能力和学习能力', calculateStar: 2 },
        { assessmentPoint: '问题解决能力', calculateStar: 3 },
        { assessmentPoint: '工作态度', calculateStar: 2 },
      ],
      colors1: [
        '#F5FFDF',
        '#EBF1FF',
        '#EBFFFF',
        '#FFF8E6',
        '#F6F0FF',
        '#EAF4FF',
        '#E0FCFF',
        '#FFF9F6',
        'rgba(232, 78, 64, 0.1)',
        'rgba(236, 64, 122, 0.1)',
        'rgba(171, 71, 188, 0.1)',
        'rgba(126, 87, 194, 0.1)',
        'rgba(92, 107, 192, 0.1)',
        'rgba(115, 143, 254, 0.1)',
        'rgba(41, 182, 246, 0.1)',
        'rgba(38, 198, 218, 0.1)',
        'rgba(43, 175, 43, 0.1)',
        'rgba(156, 204, 101, 0.1)',
        'rgba(212, 225, 87, 0.1)',
        'rgba(255, 202, 40, 0.1)',
        'rgba(255, 167, 38, 0.1)',
        'rgba(255, 112, 67, 0.1)',
        'rgba(141, 110, 99, 0.1)',
        'rgba(189, 189, 189, 0.1)',
        'rgba(120, 144, 156, 0.1)',
        'rgba(209, 124, 77, 0.1)',
      ],
      colors2: [
        '#A1DC20',
        '#1963FF',
        '#1BD0D0',
        '#F6BB20',
        '#7A35D5',
        '#3592FA',
        '#35E7FA',
        '#FFA979',
        '#E51C23',
        '#E91E63',
        '#9C27B0',
        '#673AB7',
        '#3F51B5',
        '#5677FC',
        '#03A9F4',
        '#00BCD4',
        '#259B24',
        '#8BC34A',
        '#CDDC39',
        '#FFC107',
        '#FF9800',
        '#FF5722',
        '#795548',
        '#9E9E9E',
        '#607D8B',
        '#BA6536',
      ],
    }
  },

  computed: {
    langs() {
      return this.$store.state.lang || 'cn'
    },
    aiMarkCfg() {
      return this.$store.state.aiMarkCfg
    },
    l2status() {
      return this.l2ReportInfo?.l2status
    },
    l2score() {
      return this.l2ReportInfo?.l2score || 0
    },
    l2ReportInfo() {
      return this.aiData?.l2ReportInfo
    },
    isOpenL2() {
      const isOrderAi = this.aiData?.isOrderAi || this.$store.state.companyInfo?.isOrderAi
      return isOrderAi === 2 || isOrderAi === 3
    },
    groupList() {
      if (this.isShowScoreList) {
        return this.labelGroupScoreList
      } else {
        return []
      }
    },
    interviewResult() {
      return this.$store.state.interviewResult
    },

    answerResult() {
      return this.$store.state.answerResult || []
    },
    hasVideoQues12() {
      return this.answerResult.filter(r => {
        const info = Array.isArray(r) ? r[0] : r
        return [12, 13].includes(+info.question?.problem_answer_method)
      }).length
    },
    tattooResultsArr(){
      let {armStatus,handStatus,legStatus,tattooStatus} = this.interviewResult.tattooResults
      let arr = []
      let obj = this.interviewResult.tattooResults
      
      obj.hasOwnProperty('tattooStatus') && arr.push({name:this.$t('com.report.onlinetest.text038'), result: tattooStatus})
      obj.hasOwnProperty('armStatus') && arr.push({name:this.$t('com.report.onlinetest.text040'), result: armStatus})
      obj.hasOwnProperty('handStatus') && arr.push({name:this.$t('com.report.onlinetest.text039'), result: handStatus})
      obj.hasOwnProperty('legStatus') && arr.push({name:this.$t('com.report.onlinetest.text041'), result: legStatus})
      return arr
    },
    aiData() {
      return this.$store.state.aiData
    },
    isFinished() {
      return this.interviewResult?.interview_status === 'finish'
    },
    isL5Position() {
      return this.aiData.isL5Position
    },
    l5ScoreRank() {
      return this.aiData.l5ScoreRank
    },
    l5RankPercent() {
      return +((this.l5ScoreRank?.rankPercent || 0) * 100).toFixed(2) // 防止精度丢失
    },
    l2PercentageScore() {
      return Math.round(this.l2ReportInfo?.l2PercentageScore) || 0
    },
    sort() {
      return this.isL5Position ? this.l5RankPercent : this.l2PercentageScore
    },
    showPercent() {
      // return !!this.$store.state.aiReportCfg.find(e => e.code === 'report_percent')?.isShow
      const config = this.$store.state.reportTabConfig?.find(item => item.tabType === 13)
      return config?.inUse === 0 ? false : true
    },
    showScoreLevel() {
      // return !!this.$store.state.aiReportCfg.find(e => e.code === 'report_percent')?.isShow
      const config = this.$store.state.reportTabConfig?.find(item => item.tabType === 15)
      return config?.inUse === 0 ? false : true
    },
    showStarExplain() {
      // return !!this.$store.state.aiReportCfg.find(e => e.code === 'report_percent')?.isShow
      const config = this.$store.state.reportTabConfig?.find(item => item.tabType === 16)
      return config?.inUse === 0 ? false : true
    },
    answerInfos() {
      const arrTmp = this.isL5Position ? this.l2ReportInfo?.dimLabelList : this.l2ReportInfo?.answerInfos
      const arr = (arrTmp || []).map(item => {
        const info = Array.isArray(item) ? item[0] : item
        return { ...info, visible: false }
      })
      const answerInfos = arr.sort((a, b) => {
        return a?.sortIndex - b?.sortIndex
      })
      return answerInfos
    },
    legend() {
      let leg = this.legendData.length / 2
      return [
        {
          x: 'left',
          left: '50%',
          top: 360,
          icon: 'circle',
          align: 'left',
          lineHeight: '42px',
          itemHeight: 5,
          itemWidth: 14,
          itemGap: 16,
          orient: 'vertical',
          selectedMode: false,
          data: this.legendData.slice(0, leg),
        },
        {
          x: 'right',
          left: 2,
          top: 360,
          icon: 'circle',
          lineHeight: '42px',
          itemHeight: 5,
          itemWidth: 14,
          itemGap: 16,
          orient: 'vertical',
          selectedMode: false,
          data: this.legendData.slice(leg).reverse(),
        },
      ]
    },
    series() {
      return [
        {
          // name: '',
          type: 'pie',
          roseType: 'area',
          // sort: 'ascending', // for funnel
          silent: true,
          radius: [40, this.radiusMax], // 120,100,80
          center: ['50%', '50%'],
          legendHoverLink: false,
          hoverAnimation: false,
          z: 2,
          areaStyle: {
            //color:['#3CEAFB', '#FFAC7E', '#BBE732', '#2277FF', '#1ACECE', '#F6BD22', '#8D44DD','#3895FA']
          },
          label: {
            normal: {
              show: true,
              position: 'center',
              color: '#4c4a4a',
              formatter: `{total|${this.$t('com.report.reportV3.LevelOfCompetency')}}`,
              rich: {
                total: {
                  fontSize: 12,
                  fontWeight: '800',
                  lineHeight: 14,
                  // fontFamily : "微软雅黑",
                  color: '#000',
                },
              },
            },
            emphasis: {
              //中间文字显示
              show: true,
            },
          },
          itemStyle: {
            borderWidth: 1,
            borderColor: '#fafafa',
            // color: '#fafafa'
          },
          data: this.seriesData,
        },
        {
          // name: '外环',
          type: 'pie',
          radius: [120, 100],
          data: this.seriesData1,
          center: ['50%', '50%'],
          // silent: true, // 禁用图标中的点击和触摸事
          nodeClick: false,
          labelLine: {
            show: false,
            length: 50, // 标签线的长度
            length2: 0, // 第二段线的长度
            smooth: 0.2, // 线的平滑度
          },
          // avoidLabelOverlap: true,
          align: 'center',
          label: {
            // show: false,
            position: 'outside',
            // rotate: 'tangential', // 实现！！！
            // align: 'right',
            // color:'#333',
            // formatter: '{b}',
            // rich:{
            //   transform:'rotate(-45deg)'
            // },
            // distance: 10,
            // padding: [0, -15], // 进行偏移
          },

          labelLayout: {
            width: 70,
            height: 18,
            align: 'center',
            verticalAlign: 'top',
            dy: -12,
            dx: 0,
          },
          itemStyle: {
            borderWidth: 0,
            borderRadius: 0,
            color: 'transparent',
          },
        },
        {
          type: 'pie',
          radius: [100, 80],
          center: ['50%', '50%'],
          data: this.seriesData2,
          silent: true, // 禁用图标中的点击和触摸事
          nodeClick: false,
          z: 1,
          itemStyle: {
            borderWidth: 1,
            borderColor: '#fafafa',
          },
          label: {
            normal: {
              show: false,
            },
          },
          labelLine: {
            show: false,
          },
        },
        {
          type: 'pie',
          radius: [80, 60],
          center: ['50%', '50%'],
          data: this.seriesData2,
          silent: true, // 禁用图标中的点击和触摸事
          nodeClick: false,
          z: 1,
          itemStyle: {
            borderWidth: 1,
            borderColor: '#fafafa',
          },
          label: {
            normal: {
              show: false,
            },
          },
          labelLine: {
            show: false,
          },
        },
      ]
    },
    // radiusMax = 120 // 最大值3星120; 2星100; 1星80
    radiusMax() {
      let dataArr = []
      this.answerInfos
        .filter(e => e.assessmentPoint && +e.calculateStar >= 0)
        .forEach(val => {
          dataArr.push(val.calculateStar)
        })
      return (Math.max.apply(null, dataArr) - 1) * 20 + 60
    },
    legendData() {
      return this.answerInfos
        .filter(e => e.assessmentPoint && +e.calculateStar >= 0)
        .map((item, i) => {
          let assessmentPoint = item.assessmentPoint
          // if(assessmentPoint.indexOf(' ') === -1){
          //     if (item.assessmentPoint.length > 9) {
          //       assessmentPoint = item.assessmentPoint.substring(0, 9) + '\n' + item.assessmentPoint.substring(9);
          //     }
          // }else{
          //   let arr = []
          //   assessmentPoint.split(' ').forEach((val,j) => {
          //     if(j === 2){
          //       arr.push('\n'+val)
          //     }else{
          //       arr.push(val)
          //     }
          //   });
          //   assessmentPoint = arr.join(' ')
          // }
          return {
            name: assessmentPoint + `\n${item.calculateStar}${this.$t('com.report.reportV3.text154')}`,
            // value:1,
            itemStyle: { color: this.colors2[i] },
            textStyle: { color: '#666', fontSize: '14px' },
          }
        })
    },
    seriesData1() {
      let lens = this.answerInfos.length
      return this.answerInfos
        .filter(e => e.assessmentPoint && +e.calculateStar >= 0)
        .map((item, i) => {
          let assessmentPoint = item.assessmentPoint
          let flag = false
          if (lens === 3) {
            if (i === 0 || i === 2) flag = true
          }
          if (lens === 4) {
            if (i === 1 || i === 2) flag = true
          }
          if (lens === 5) {
            if (i === 1 || i === 3) flag = true
          }
          if (lens === 6) {
            if (i === 2 || i === 3) flag = true
          }
          if (lens === 7) {
            if (i === 2 || i === 4 || i === 5) flag = true
          }
          if (lens === 8) {
            if (i === 2 || i === 3 || i === 4 || i === 5) flag = true
          }

          return {
            name: assessmentPoint + `\n${item.calculateStar}${this.$t('com.report.reportV3.text154')}`,
            value: 1,
            itemStyle: { color: this.colors1[i] },
            label: { color: this.colors2[i], fontSize: 12, lineHeight: 14 }, //this.colors2[i]
            labelLine: {
              length2: flag ? 12 : 0,
            },
          }
        })
    },

    seriesData2() {
      return this.answerInfos
        .filter(e => e.assessmentPoint && +e.calculateStar >= 0)
        .map(() => {
          return {
            name: '',
            value: 1,
            itemStyle: { color: 'transparent' },
            label: { color: 'transparent' },
          }
        })
    },
    seriesData() {
      return this.answerInfos
        .filter(e => e.assessmentPoint && +e.calculateStar >= 0)
        .map((item, i) => {
          const score = item.calculateStar
          return {
            name: item.assessmentPoint,
            value: score,
            itemStyle: {
              color: {
                type: 'radial',
                x: 190,
                y: 220,
                r: 240,
                // y2:0,
                colorStops: [
                  { offset: 0.1, color: this.colors1[i] },
                  { offset: 0.22 * score, color: this.colors2[i] },
                ],
                global: true,
              },
            },
          }
        })
    },
    // seriesData() {
    //   return this.answerInfos.length > 0 && this.answerInfos.filter(e => e.assessmentPoint && +e.calculateStar >= 0).map(item => {
    //     const info = Array.isArray(item) ? item[0] : item
    //     const score = info.calculateStar
    //     return score
    //   })
    //},
    isL2ScoreStop() {
      return this.$store.state.interviewMsg.isL2ScoreStop !=1
    },
    showL2Radar() {
      return this.isOpenL2 && (this.l2status === 0|| this.isL2ScoreStop == 1) && this.answerInfos && this.answerInfos.length > 2
    },
    l5Score() {
      return this.l5ScoreRank?.l5Score
    },

    gradeExplain() {
      return this.isL5Position ? this.l2ReportInfo?.dimLabelList?.filter(e => e.assessmentPoint && +e.calculateStar >= 0) : []
    },

    isescoreCN() {
      return (this.aiData.isescoreList || []).find(item => {
        return item.lang === 'cn'
      })
    },

    isescoreEN() {
      return (this.aiData.isescoreList || []).find(item => {
        return item.lang === 'en'
      })
    },
    computedLabelGroupScoreList() {
      return this.labelGroupScoreList.concat().unshift({
        label: this.$t('com.report.reportV3.text050'),
        value: this.totalScore,
      })
    },
    // 是否是安永公司
    isAnyongCompany() {
      const companyId = this.$store.state.companyId
      return this.$store.state.anyongCompanyId.includes(companyId) ? true : false
    },
    // 是否展示英语能力
    isShowEnglishability() {
      const config = this.$store.state.reportTabConfig?.find(item => item.tabType === 19)
      return config?.inUse === 0 ? false : true
    },
    //携程特定职位显示总分
    get isXCpositon() {
      const positionid = this.interviewResult?.positionid
      if (
        [
          '9f23c660e8864921903857f4d9841b21', //mini粤语+英语
          'e55c4e03fb324870a372bf31f74c2fd5', //uat粤语+英语
          'be6ea142f6884da68ea9e887bf016f6f', //正式粤语加英语
        ].includes(positionid)
      ) {
        return 1
      } else if (
        [
          '7923f8dbd5b84a36b73512107c4453fe', //正式纯英语
          '1dea4b1c04664cdd9f59e9c01d614b79', //正式纯英语
          '60b31ac3658e42548a88fa045da3d26e', //mini纯英语
          '9255bd6c2cc2425491d217571d634893', //mini纯英语
          '8b52e0440add44d39708744c24c64a2f', //uat纯英语
          '930819c00a0442c19b99bb382a59e317', //uat纯英语
        ].includes(positionid)
      ) {
        return 2
      } else if (
        [
          'b893d304c41d4a9d925598cbbb0a594a', //mini纯粤语
          'e384a3d3431a4e7abadce5cd2df1a7d8', //uat纯粤语
          '941cd95301c541a4900222113b460f5b', //正式纯粤语
        ].includes(positionid)
      ) {
        return 3
      } else {
        return 0
      }
    },
    interviewId() {
      return this.$store.state.interviewId
    },
    indicator() {
      return (
        this.answerInfos.length > 0 &&
        this.answerInfos
          .filter(e => e.assessmentPoint && +e.calculateStar >= 0)
          .map(item => {
            const info = Array.isArray(item) ? item[0] : item
            const score = info.calculateStar.toFixed(0)
            return {
              name: `${info.assessmentPoint}(${i18n.t('com.report.reportV3.text059', [score])})`,
              max: this.isL5Position ? 3 : 5,
            }
          })
      )
    },
    radar() {
      return {
        indicator: this.indicator,
        scale: true,
        axisTick: {
          //刻度设置
          show: true,
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: '#B1D8FF',
          },
        },
        axisName: {
          show: true,
          color: '#333',
          fontWeight: 'blod',
          width: 10,
          overflow: 'breakAll',
          rich: {
            width: 10,
            overflow: 'breakAll',
          },
          formatter: function (text) {
            var strlength = text.length
            if (strlength % 8 !== 0) {
              text = text.replace(/\S{8}/g, function (match) {
                return match + '\n'
              })
            } else {
              text = text.replace(/\S{8}/g, function (match) {
                return match + '\n'
              })
              strlength = text.length
              text = text.substring(0, strlength)
            }
            return text
          },
        },
        nameGap: 10,
        splitNumber: this.isL5Position ? 3 : 5, //刻度
        splitLine: {
          //刻度连接线
          show: false,
        },
        splitArea: {
          show: true,
          areaStyle: {
            color: ['rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)', 'rgba(246, 250, 252, 1)'],
          },
        },
      }
    },
    highchartsData1() {
      return this.answerInfos
        .filter((e) => e.assessmentPoint && +e.calculateStar >= 0 && +e.calculateRatio > 0)
        .map((item, i) => {
          const score = item.calculateStar
          return {
            name: item.assessmentPoint,
            value: score,
            colors:this.colors2[i],
            y: item.calculateRatio,
          }
        })
    },
    highchartsData2() {
      return this.answerInfos
        .filter((e) => e.assessmentPoint && +e.calculateStar >= 0 && +e.calculateRatio > 0)
        .map((item, i) => {
          const score = item.calculateStar
          return {
            name: item.assessmentPoint,
            value: score,
            y: item.calculateRatio,
            z: score*score,
          }
        })
    },
  },
  watch: {
    aiData: {
      immediate: true,
      handler(val) {
        if (val) {
          this.inintLableScore()
        }
      },
    },
  },
  created() {
    if (this.gradeExplain?.length > 0 && this.l2ReportInfo?.answerInfos?.length > 0) {
      this.gradeExplain.forEach(e => {
        this.l2ReportInfo.answerInfos.forEach(el => {
          if (e.assessmentPoint === el.assessmentPoint) {
            e.assessmentDesc = el.assessmentDesc || ''
          }
        })
      })
    }
    if (this.isXCpositon) this.getLanguageScore()
    this.getEnglishability()
  },
  mounted() {
    this.$nextTick(() => {
      // this.initScoreRadar()
      this.initHighcharts()
    })
  },
  methods: {
    async getLanguageScore() {
      console.log('this.interviewId---', this.interviewId)
      if (!this.interviewId) return
      const res = await getLanguageScore({ interviewId: this.interviewId })
      const { code, msg, data } = res
      if (code === 0) {
        this.languageScore = data
      } else {
        this.$toast({ type: 'fail', message: msg })
      }
    },

    async getEnglishability() {
      console.log('this.interviewId---', this.interviewId)
      if (!this.interviewId) return
      const res = await getEnglishability({ interviewId: this.interviewId })
      const { code, msg, data } = res
      if (code === 0) {
        this.enSocre = data
      } else {
        this.$toast({ type: 'fail', message: msg })
      }
    },
    enColor(score){
      if(score >= 90){
        return 'linear-gradient( 90deg, #ADFA5F 0%, #44FEBF 100%)'
      }else if(score >= 60 && score < 90){
        return 'linear-gradient( 90deg, #5AFFF2 0%, #00B2FF 100%)'
      }else{
        return 'linear-gradient( 90deg, #FFD0A2 0%, #FFA400 100%)'
      }
    },

    changeIconStatus() {
      // this.isActive = !this.isActive
    },
    isPositiveInteger(value) {
      return true || value >= 0
    },
    initScoreRadar() {
      if (this.seriesData && this.seriesData.length && this.seriesData.length > 2) {
        const chartDom = document.getElementById('scoreRadar')
        if (chartDom) {
          const myChart = echarts.init(chartDom)
          const option = {
            // radar: this.radar,
            series: this.series,
            tooltip: {
              trigger: 'item',
              position: 'top',
              formatter: function (params) {
                let name = params.name // 获取名称
                return name // 只返回名称
              },
            },
            toolbox: {
              show: false,
            },
            calculable: false,
            animation: false,
            // legend: this.legend
          }
          option && myChart.setOption(option)
        }
      }
    },
    
    initHighcharts() {
      // 创建渐变色
      let HighchartColors = this.colors2
      // let HighchartColors = this.colors2.map((color,i)=> {
      //     return {
      //         radialGradient: { cx: 0.5, cy: 0.4, r: 0.5 },
      //         stops: [
      //             [0, this.colors1[i]],
      //             [1, color] // darken
      //         ]
      //     };
      // });
      // console.log('Highcharts.getOptions()',HighchartColors)
      let that = this
      // Data retrieved from https://worldpopulationreview.com/country-rankings/countries-by-density
      var chart = Highcharts.chart('scoreRadar', {
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false,
        },
        // exporting.buttons.exportButton.enabled
        chart: {
          type: 'variablepie',
          custom: {},
          events: {
            render() {
              const chart = this,
                series = chart.series[0];
              let customLabel = chart.options.chart.custom.label;

              if (!customLabel) {
                customLabel = chart.options.chart.custom.label =
                  chart.renderer.label(
                    `<strong>${that.$t('com.report.reportV3.LevelOfCompetency')}</strong>`
                  )
                    .css({
                      color: '#333',
                      textAnchor: 'middle'
                    })
                    .add()
              }

              const x = series.center[0] + chart.plotLeft,
                y = series.center[1] + chart.plotTop - (customLabel.attr('height') / 5)

              customLabel.attr({ x, y })
              // Set font size based on chart diameter
              customLabel.css({
                fontSize: `${series.center[2] / 16}px`
              })
            }
          },
        },
        title: {
          text: '',
          align: 'left'
        },
        tooltip: {
          headerFormat: '',
          pointFormat: //'<span style="fontSize:14px;color:{point.color}">\u25CF</span>' +
            '<span style="fontSize:14px;">{point.name} ' + i18n.t('com.report.reportV3.text059', ['{point.value}'])+'</span>'
            //  +'Area (square km): <b>{point.y}</b><br/>' +
            // 'Population density (people per square km): <b>{point.z}</b><br/>'
        },
        plotOptions: {
          variablepie: {
            dataLabels: {
              enabled: true,
              crop: false,
              overflow: "allow",
              position: 'center',
              borderWidth: 0,
              connectorWidth: 0,
              padding: -14,
              x: 34,
              y: -10,
              connectorPadding: 0,
              style: {
                textOverflow: 'ellipsis',
                textAnchor: 'middle',
                // textAlign: 'center',
                width: '80px',
              },
              formatter: function() {
                return '<span style="font-weight:normal;color:'+ this.point.colors +';font-size:14px">' + this.point.name + '<br/>'
                + i18n.t('com.report.reportV3.text059', [this.point.value])+ '</span>'

                //  + Highcharts.numberFormat(this.percentage, 2) + '%</span>'
              }
            },
          },
          series: {
            states: {
              hover: {
                enabled: false
              },
              inactive: {
                enabled: true,
                opacity: 1
              },
            }
          }
        },
        series: [{
          name: 'Registrations',
          colorByPoint: true,
          size: '61%',
          innerSize: '85%',
          // enableMouseTracking: false,
          lineWidth: 0,
          data: this.highchartsData1,
          colors: this.colors1
        },
        {
          minPointSize: 2,
          innerSize: '50%',
          size: '51%',
          center: ["50%", "50%"],
          minsize: 1,
          zMin: 0,
          zMax: 9,
          name: 'countries',
          borderRadius: 3,
          label: {
            enabled: false
          },
          dataLabels: {
            enabled: false
          },
          enableMouseTracking: false,
          data: this.highchartsData2,
          colors: HighchartColors
        }]
      });

    },
  
    inintLableScore() {
      const val = this.aiData
      if (val.labelGroupScoreList && val.labelGroupScoreList.length) {
        // if (val.labelGroupScoreList.length === 1 && val.labelGroupScoreList[0].label === '无标签') {
        //   this.showLabelGroupScoreList = false
        // } else {
        let totalScore = 0
        val.labelGroupScoreList.forEach(item => {
          this.labelGroupScoreList.push({
            label: item.label,
            value: item.labelScore,
          })
          totalScore = totalScore + item.labelScore
        })
        this.totalScore = totalScore

        this.showLabelGroupScoreList = true
      }
      // }
    },
  },
}
</script>
<style lang="scss">
.summary__wrapper {
  // width: calc(100% - 16px);
  margin: auto;
  .top__title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 20px;
    color: #333333;
    text-align: left;
    font-style: normal;
    padding: 0 8px;
    position: relative;
    height:80px;
    &::after{
      content: '';
      display: block;
      width: 100%;
      height: 8px;
      background: #1890FF;
      opacity: 0.15;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .row {
    // padding: 0 24px;
    // display: flex;
    // justify-content: space-between;
    align-items: center;
    line-height: 25px;
    flex-wrap: wrap;
    .flexrow {
      display: flex;
      flex-direction: row;
      .lsiwu {
        font-size: 16px;
        font-weight: 500;
        color: #1890ff;
        margin-right: 9px;
        line-height: 14px;
      }
    }
    > div {
      flex: 1;
      // max-width: 33.33%;
      // min-width: 33.33%;
      // flex-direction: column;
      // display: flex;
      // justify-content: center;
      // align-items: center;
      > * {
        height: 25px;
        display: flex;
        align-items: center;
      }
      .vip-img {
        width: 80%;
        img {
          margin: 0;
          padding: 0;
          width: 100%;
        }
      }
    }

    .lable-item {
      .number {
        font-size: 22px;
        font-weight: 600;
      }
      .score {
        font-size: 12px;
      }
      .active_icon {
        transform: rotate(90deg);
      }
      .top-lable {
        i {
          color: #1890ff;
        }
      }
    }
  }
  .row.ise {
    margin-top: 10px;
    margin-bottom: 5px;
    // > div {
    //   min-width: 50%;
    //   max-width: 50%;
    // }
    .huase {
      font-size: 14px;
      font-weight: 500;
      color: #ffca3a;
      margin-left: 9px;
      line-height: 14px;
    }
  }

  .row2 {
    width: 100%;
    // padding: 0 5vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    line-height: 25px;
    flex-wrap: wrap;

    .lable-item {
      margin-top: 10px;
      width: 30vw;
      flex-direction: column;
      display: flex;
      justify-content: center;
      align-items: center;
      > * {
        height: 25px;
        display: flex;
        align-items: center;
      }
      .vip-img {
        width: 80%;
        img {
          margin: 0;
          padding: 0;
          width: 100%;
        }
      }
      .number {
        font-size: 22px;
        font-weight: 600;
      }
      .score {
        font-size: 12px;
      }
      .top-lable {
        i {
          color: #1890ff;
        }
      }
    }
  }
  .xingx {
    font-size: 10px;
    font-weight: 400;
    color: #6d7278;
    margin-bottom: 5px;
  }
  .progres {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .txt {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 5px;
      .num {
        font-size: 24px;
        font-weight: 500;
        color: #1890ff;
      }
    }
  }
  .data__wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 8px 0 5px;
    .data__title {
      font-size: 14px;
      color: #333;
    }
    .data__score {
      margin-left: 5px;
      font-size: 14px;
      font-weight: 400;
      color: #ffca3a;
    }
  }
  .enData_summary{
    margin-bottom: 20px;
    .enData_left{
      min-height: 320px;
      background: #FAFAFA;
      border-radius: 8px;
      width:100%;
      margin-top: 12px;
    }
      
      .enData_left__score{
        padding: 16px;
        display: flex;
        .enData_left__Level{
          font-weight: 500;
          font-size: 14px;
          color: #333333;
          line-height: 22px;
          text-align: left;
          font-style: normal;
          margin-right: 10px;
          line-height: 64px;
        }
      }
      .left1{
        width: 264px;
        display: flex;
        text-align: center;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #BFCCDA;
        line-height: 17px;
        text-align: center;
        font-style: normal;
      }
      .left2{
        width: 330px;
        margin: 4px 0 12px;
        height: 20px;
        display: flex;
        align-items: center;
        
        .enData__label{
          width: 60px;
          min-width: 60px;
          margin-right: 6px;
          text-align: right;
          line-height: 14px;
        }
        .enData__progress{
          width: 0;
          height: 16px;
          background: linear-gradient( 90deg, #F5C3FE 0%, #813DF5 100%);
          border-radius: 0px 100px 100px 0px;
          z-index: 1;
        }
        .enData__score{
          padding: 0 4px;
          margin-left: 6px;
          background: #FFFFFF;
          box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.12);
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 12px;
          color: #000000;
          line-height: 20px;
          text-align: center;
          font-style: normal;
          position: relative;
          // &::after{
          //   content: '';
          //   display: inline-block;
          //   width: 0;
          //   height: 0;
          //   border-width: 4px;
          //   border-style: solid;
          //   border-color: transparent #fff transparent transparent;
          //   margin-left: -4px;
          //   margin-right: 4px;
          //   position: absolute;
          //   left: -3px;
          //   top: 6px;
          //   box-shadow: 0px 1px 2px 0px rgba(0,0,0,0.12);
          // }
        }
      }
      .left3{
        text-align: left;
        margin-left: -9px;
      }
      .enData_left__content{
        position: relative;
        .enData__line{
          width: 264px;
          position: absolute;
          left: 0;
          top: 10px;
          z-index: 0;
          display: flex;
          border-left: 1px solid #d8d8d8;
          
          span:not(:last-child){
            width: 1px;
            height: 40px;
            border-right: 1px dashed #f2f2f2;
          }
        }
      }
      .enData_left__tips{
        margin: 0 16px 8px;
        padding-bottom: 16px;
        h3{
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          font-size: 14px;
          color: #333333;
          line-height: 22px;
          text-align: left;
          font-style: normal;
          margin-bottom: 8px;
        }
        >div{
          font-family: PingFangSC, PingFang SC;
          font-weight: 400;
          font-size: 14px;
          color: #666666;
          line-height: 22px;
          text-align: left;
          font-style: normal;
        }
      }

      
    .enData_right{
      min-height: 320px;
      background: #FAFAFA;
      border-radius: 8px;
      width:100%;
      margin-top: 12px;
      .enData_left__content{
        position: relative;
        .enData__line{
          width: 250px;
          top: 22px;
          left: 65px;
          height: 190px;
          span{
            height: 190px;
          }
        }
        // css选取下面第2个left2类名
        .left2:nth-of-type(3) .enData__progress{
          background: linear-gradient( 90deg, #5AFFF2 0%, #00B2FF 100%);
        }
        .left2:nth-of-type(4) .enData__progress{
          background: linear-gradient( 90deg, #5AFFF2 0%, #00B2FF 100%);
        }
        .left2:nth-of-type(5) .enData__progress{
          background: linear-gradient( 90deg, #FFD0A2 0%, #FFA400 100%);
        }
        .left2:nth-of-type(6) .enData__progress{
          background: linear-gradient( 90deg, #FFD0A2 0%, #FFA400 100%);
        }
        .left2:nth-of-type(7) .enData__progress{
          background: linear-gradient( 90deg, #ADFA5F 0%, #44FEBF 100%);
        }
      }
      .left1{
        width: 250px;
        margin-left: 62px;
      }
      .left3{
        margin-left: 58px;
      }
      .left2{
        width: 350px;
        min-width: 280px;
        margin: 17px 0;
      }
      .enData__progress{
        width: 0px;
      }

      .enData_left__Level{
        line-height: 32px;
        margin-bottom: 10px;
      }
      .enData_left__score{
        flex-direction: column;
      }
    }
  }
  
  
  .tattooResults{
    font-weight: 500;
    font-size: 14px;
    color:#333;
    display: flex;
    flex-wrap: wrap;
    >div{
      margin-right: 16px;
      align-items: center;
      display: flex;
      >span{
        min-width: 60px;
      }
    }
  }

  .interview_time {
    width: 100%;
    margin: 10px 0px;
    padding: 10px;
    background: #f3f9ff;
    border-radius: 8px;
    .use_time {
      margin-bottom: 10px;
      font-size: 14px;
      color: #333333;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .trans_icon {
        color: #1890ff;
        .active_icon {
          transform: rotate(90deg);
        }
      }
      .clockimg {
        width: 16px;
        height: 16px;
        margin-right: 2px;
        position: relative;
        top: 3px;
      }
    }
    .time {
      // padding: 10px;
      // background: #f8f8f8;
      border-radius: 5px;
      font-size: 12px;
      color: #6d7278;
      .begin_time {
        span {
          font-weight: 400;
        }
      }
      .begin_time + .begin_time {
        margin-top: 5px;
      }
    }
  }
  .sumupo {
    padding: 16px;
    margin: 12px 0 13px;
    background: rgba(24, 144, 255, 0.05);
    border-radius: 8px;
    border: 1px solid #1890ff;
    .mt16 {
      margin-top: 16px;
    }
    .el-divider {
      margin: 12px 0;
    }
    .spctit {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
      margin-bottom: 8px;
      line-height: 24px;
    }
    .spcdtil {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      .usitmbot {
        font-family: none;
      }
    }
  }
  .tip {
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    line-height: 17px;
  }
  .l2-question {
    margin: 0 auto;
    .l2-q_t {
      margin-bottom: 20px;
    }
    .l2-q_r {
      height: 400px;
      width: 360px;
      margin: auto;
    }
  }
  .unscramble {
    padding: 16px 16px 0;
    background: #f6fafc;
    border-radius: 8px;
    border: 1px solid #ddd;
    .ustitl {
      color: #333333;
      font-size: 16px;
      font-weight: 500;
    }
    .el-divider {
      margin: 12px 0;
    }
    .usitm {
      margin-bottom: 12px;
      .usitmtop {
        color: #333333;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin-bottom: 12px;
        .huase {
          font-size: 14px;
          font-weight: 500;
          color: #ffca3a;
          margin-left: 5px;
        }
      }
      .usitmbot {
        color: #666666;
        font-size: 14px;
        font-weight: 400;
        margin: 5px 0px;
        white-space: pre-wrap;
      }
      .sumup {
        padding: 16px;
        border-radius: 8px;
        background: #ffffff;
        border: 1px solid #dddddd;
        .spctit {
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 5px;
        }
        .spcdtil {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          .usitmbot {
            font-family: none;
          }
          .starimg {
            width: 24px;
            height: 12px;
            position: relative;
            top: 5px;
          }
          .starimg_e {
            width: 31px;
            height: 13px;
            position: relative;
            top: 5px;
          }
        }
        .hei30 {
          height: 30px;
        }
      }
    }
  }
}
.mode-img1 {
  img {
    height: 18px;
  }
}
</style>
<style>
.hei30 .el-loading-mask {
  z-index: 1;
}
div[data-height3] {
  height: 5px;
}
</style>

/* eslint-disable quotes */
export default {
  text001: 'Essay question',
  text002: 'Written test report',
  // eslint-disable-next-line quotes
  text003: `His/Her answer`,
  text004: 'correct answer',
  text005: 'Correct',
  text006: 'Error',

  ext007: 'Online Exam',
  text008: `This module covers the candidate's performance on all types of questions, including: single choice, multiple choice, true/false, sorting, essay.`,
  text009: '{name} Answer and score: {score}/{total}',
  text010: 'Note:',
  text011:
    'The scores for single choice, true/false, and sorting questions will only be included in the total score of the chart after the question scores and standard answers are set. The score for essay questions will only be included in the total score of the chart after the question scores are set and evaluated by the interviewer.',
  text012: 'Answer Summary',
  text013: 'Number of Correct Answers:',
  text014: 'Number of Incorrect Answers:',
  text015: '( This question is not scored. )',
  text016: 'No content can be viewed',
  text017: 'Incomplete Answer',
  text018: 'Incorrect Answer',
  text019: 'Correct Answer',

  text030: 'Candidate did not respond',
  text030_1: 'Not respond',
  text031: '{0}Points',
  text032: 'Correct Answer',
  text033: 'Correct Answer',
  text034: `Candidate's Answer`,
  text035: 'Qualified',
  text036: 'Unqualified',
  text037: 'Color Blindness Test',
  text038: `Tattoo Test`,
  text039: `Finger/Arm Integrity Test`,
  text040: `Finger Flexibility Test`,
  text041: `Squat Test`,
  text042: `The reason for this failure`,
  text043: 'Tattoo and Limb Test',

  spt: 'Video question',
  lst: 'Essay question',
  dxt: 'Single choice question',
  ddxt: 'Multiple choice question',
  tkt: 'Fill-in-the-blanks question',
  pdt: 'Judge question',
  pxt: 'Sequencing question',
  bct: 'Coding question',
  kyt: 'Accent question',
}

<template>
  <div class="objective-problem">
    <div v-if="currentModuleQuestionList && currentModuleQuestionList.length">
      <template v-for="(questionItems, index) in currentModuleQuestionList">
        <template v-for="(questionItem, $index) in Array.isArray(questionItems) ? questionItems : [questionItems]">
          <div :key="index + '-' + $index">
            <div class="question-type">
              <span> {{ index + 1 }}.{{ getTypeName(questionItem.question.problem_answer_method) }} </span>

              <span v-if="questionItem.question.is_not_score && questionItem.question.is_not_score === 1"> {{ $t('com.report.onlinetest.text015') }} </span>
              <span v-else>
                (<span style="color: rgb(24, 144, 255)">{{ questionItem.question.actualscore }}</span>
                <span>/</span>
                <span>{{ $t('com.report.onlinetest.text031', [questionItem.question.labelscore]) }}</span
                >)
              </span>
            </div>

            <HtmlParse :htmlStr="questionItem.question.problem" :show-label="false" :use-question-content="true" />
            <ReadingQuestionItem :questionArray="questionItem.subAnswerList" />
            <br />
          </div>
        </template>
      </template>
    </div>
    <Empty v-else :title="$t('com.report.onlinetest.text016')" />
  </div>
</template>
<script>
// import { Vue, Component } from 'vue-property-decorator'
// import HtmlParse from '@/views/hologram/ai-interview/HtmlParse.vue'
// import hologram from '@/store/modules/hologram'
import { saveAs } from 'file-saver'
import HtmlParse from '@/components/HtmlParse'
import Empty from '@/components/reportV3/common/empty.vue'
import ReadingQuestionItem from '@/components/reportV3/reading-question-item/index.vue'

// @Component({ )
export default {
  components: { HtmlParse, Empty, ReadingQuestionItem },
  data() {
    return {
      types: [50],
      judgeType: [2, 3, 5],
    }
  },

  computed: {
    //获取当前模块题目
    currentModuleQuestionList() {
      // eslint-disable-next-line camelcase
      return this.answerResult.filter(r => {
        const info = Array.isArray(r) ? r[0] : r
        return this.types.includes(+info.question?.problem_answer_method)
      })
    },

    //获取所有题目
    answerResult() {
      return this.$store.state.answerResult
    },
  },
  methods: {
    downLoadClick(e) {
      const target = e?.target
      if (target?.nodeName === 'A') {
        if (target.hasAttribute('href')) {
          e.preventDefault()
          const href = target.getAttribute('href') || ''
          const fileName = target.innerText
          saveAs(href, fileName)
        }
      }
      console.log('Event', e)
    },
    optionExtContent(question) {
      if (!(question.optionExt || []).length) return question.answer
      let answerList = (question.answer || '').split(',')
      let res = []
      answerList.forEach(item => {
        const content = question.optionExt.find(ext => ext.optionCode === item)?.optionSupplementaryContent
        if (content) {
          const str = `${item}-${content}`
          res.push(str)
        } else {
          res.push(item)
        }
      })
      return res.join(',')
    },
    getTypeName(type) {
      const types = [
        { value: 1, label: `${this.$t('com.report.onlinetest.spt')}` },
        { value: 0, label: `${this.$t('com.report.onlinetest.lst')}` },
        { value: 2, label: `${this.$t('com.report.onlinetest.dxt')}` },
        { value: 3, label: `${this.$t('com.report.onlinetest.ddxt')}` },
        { value: 8, label: `${this.$t('com.report.onlinetest.tkt')}` },
        { value: 4, label: `${this.$t('com.report.onlinetest.pdt')}` },
        { value: 5, label: `${this.$t('com.report.onlinetest.pxt')}` },
        { value: 7, label: `${this.$t('com.report.onlinetest.bct')}` },
        { value: 6, label: `${this.$t('com.report.onlinetest.kyt')}` },
        { value: 11, label: `${this.$t('com.report.onlinetest.text037')}` },
        { value: 50, label: `${this.$t('com.report.base.text0231')}` },
      ]
      const obj = types.find(item => item.value === type)
      if (obj) {
        return obj.label
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.objective-problem{
  padding-bottom: 60px;
}
.question-title,
.question-answer {
  .objective-answer {
    pre {
      font-size: 16px;
    }
  }
  pre {
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-word;
    font-family: 'Helvetica Neue', Helvetica, 'PingFang SC', 'Hiragino Sans GB', 'Microsoft Yahei', 'WenQuanYi Micro Hei', sans-serif;
    // display: flex;
    // align-items: baseline;
    img {
      max-width: 100%;
    }
  }

  .green {
    color: #6ed401;
  }
  .red {
    color: #ff0000;
  }
  .yellow {
    color: #f4d75e;
  }
}

.question-type {
  margin-top: 8px;
  font-weight: bold;
}

.question-answer {
  margin-top: 5px;
  padding: 12px 16px;
  border-radius: 5px;
  // background: #f8f8fa;
  border: 1px dashed #dddddd;

  .answer-detail {
    // padding-left: 20px;
    display: flex;
    color: #c1cad9;
    align-items: center;
    margin-bottom: 10px;
    .question-tag {
      border-radius: 2px;
      background-color: #5facff;
      color: #ffffff;
      padding: 3px 12px;
    }
  }

  .right-answer-wrapper {
    .right-key {
      padding-left: 20px;
    }
  }
}
.option-wrapper {
  padding-left: 20px;
}
</style>

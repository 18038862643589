<template>
  <div class="anti-cheating">
    <div v-if="showContent" class="wrapper">
      <!-- 风险提示 -->
      <div v-if="antiCheatingInfo.checkTextRepetition || antiCheatingInfo.checkPhotoDetection || antiCheatingInfo.abnormalDetection || antiCheatingInfo.candidateLoginLimitTimes > -1" class="outer-card">
        <div class="outer-card-title flex">
          <p class="label flex">{{ $t('com.report.antiCheating.riskTip') }}</p>
          <!-- <p class="level">高风险</p> -->
        </div>
        <div class="inner-card flex">
          <div v-if="antiCheatingInfo.checkTextRepetition" class="inner-card-item">
            <h3 class="card-title color">{{ $t('com.report.antiCheating.textSimilarity') }}</h3>
            <p class="line">
              <span class="card-label">{{ $t('com.report.antiCheating.exactMatch') }}：</span>
              <span class="card-percent">{{ antiCheatingInfo.maxWordTextSimilarity || 0 }}%</span>
            </p>
            <p class="line">
              <span class="card-label">{{ $t('com.report.antiCheating.fuzzyMatch') }}：</span>
              <span class="card-percent">{{ antiCheatingInfo.maxAiTextSimilarity || 0 }}%</span>
            </p>
          </div>
          <div v-if="antiCheatingInfo.checkPhotoDetection" class="inner-card-item">
            <h3 class="card-title color">{{ $t('com.report.antiCheating.abnormalPhotos') }}</h3>
            <p>
              <span class="card-count">{{ antiCheatingInfo.abnormalPictureNum || 0 }}</span>
              <span class="unit">{{ $t('com.report.antiCheating.photos') }}</span>
            </p>
          </div>
          <div v-if="antiCheatingInfo.abnormalDetection" class="inner-card-item">
            <h3 class="card-title color">{{ $t('com.report.antiCheating.exitCount') }}</h3>
            <p>
              <span class="card-count">{{ recordData.usedTimes || 0 }}</span>
              <span class="unit">{{ $t('com.report.antiCheating.times') }}</span>
            </p>
          </div>
          <div v-if="antiCheatingInfo.candidateLoginLimitTimes > -1" class="inner-card-item">
            <h3 class="card-title color">{{ $t('com.report.antiCheating.lognCount') }}</h3>
            <p>
              <span class="card-count">{{ recordData?.loginInfoList?.length || 0 }}</span>
              <span class="unit">{{ $t('com.report.antiCheating.times') }}</span>
            </p>
          </div>
        </div>
        <p class="tip-text">{{ $t('com.report.antiCheating.riskDesc') }}</p>
      </div>
      <!-- 文本相似度 -->
      <div v-if="antiCheatingInfo.checkTextRepetition && antiCheatingInfo.textSimilarityRes.length" class="module-card">
        <h2 class="module-title left-icon">{{ $t('com.report.antiCheating.textSimilarity') }}</h2>
        <div class="problem-list">
          <div v-for="(item, index) in antiCheatingInfo.textSimilarityRes || []" :key="index" class="problem-list-item">
            <p class="text">{{ index + 1 }}. {{ getQuestionText(item.questionText) }}</p>
            <div class="answer-card">
              <h4 class="answer-title">{{ $t('com.report.antiCheating.answer') }}</h4>
              <p class="answer-content">{{ item.answer }}</p>
            </div>
            <div class="similar-container">
              <div class="similar-card flex">
                <p>
                  <span>{{ $t('com.report.antiCheating.textSimilarity') }}（{{ $t('com.report.antiCheating.accurate') }}）：</span>
                  <span class="percent">{{ item.maxWordRate || 0 }}%</span>
                </p>
                <a @click="viewSimilarDetail('accurate', item)" class="btn">{{ $t('com.report.antiCheating.view') }}</a>
              </div>
              <div class="similar-card flex">
                <p>
                  <span>{{ $t('com.report.antiCheating.textSimilarity') }}（{{ $t('com.report.antiCheating.fuzzy') }}）：</span>
                  <span class="percent">{{ item.maxAiRate || 0 }}%</span>
                </p>
                <a @click="viewSimilarDetail('fuzzy', item)" class="btn">{{ $t('com.report.antiCheating.view') }}</a>
              </div>
            </div>
          </div>
        </div>
        <van-divider />
      </div>
      <!-- 异常照片 -->
      <div v-if="antiCheatingInfo.checkPhotoDetection && abnormalPictures.length" class="module-card">
        <h2 class="module-title left-icon">{{ $t('com.report.antiCheating.abnormalPhotos') }}</h2>
        <div class="images-container">
          <p class="text">{{ $t('com.report.antiCheating.abnormalDesc') }}</p>
          <!-- 照片墙 -->
          <custom-image-viewer display-mode="fold" :image-list="abnormalPictures" />
        </div>
        <van-divider></van-divider>
      </div>
      <!-- 跳出次数 -->
      <div v-if="antiCheatingInfo.abnormalDetection && recordData.cheatLogs.length" class="module-card">
        <h2 class="module-title left-icon">{{ $t('com.report.antiCheating.exitCount') }}</h2>
        <div class="page-exit-container">
          <p class="info-box flex">
            <label>{{ $t('com.report.antiCheating.pageExitCount') }}：</label>
            <span class="info">{{ recordData.usedTimes || 0 }}{{ $t('com.report.antiCheating.times') }}</span>
          </p>
          <p class="info-box flex">
            <label>{{ $t('com.report.antiCheating.pageExitTime') }}：</label>
            <span class="info">{{ recordData.totalTime }}</span>
          </p>
          <p class="info-box flex">
            <label>{{ $t('com.report.antiCheating.detailsLog') }}：</label>
          </p>
          <timeline>
            <timeline-item v-for="(record, index) in recordData.cheatLogs || []" :key="index">
              <template #dot>
                <div class="outer-circle">
                  <div class="inner-circle" />
                </div>
              </template>
              <span class="text">
                {{ moment(record.startTime).format('YYYY-MM-DD HH:mm:ss') }} ~
                {{ moment(record.endTime).format('YYYY-MM-DD HH:mm:ss') }}
              </span>
              <br />
              <span class="text">{{ $t('com.report.antiCheating.candidateExitPage') }}{{ record.time }}</span>
            </timeline-item>
          </timeline>
          <van-divider></van-divider>
        </div>
      </div>
      <!-- 登录次数 -->
      <div v-if="antiCheatingInfo.candidateLoginLimitTimes > -1" class="module-card">
        <h2 class="module-title left-icon">{{ $t('com.report.antiCheating.lognCount') }}</h2>
        <div class="page-exit-container">
          <p class="info-box flex">
            <label>{{ $t('com.report.antiCheating.actLognCount') }}</label>
            <span class="info">{{ recordData?.loginInfoList?.length || 0 }}{{ $t('com.report.antiCheating.times') }}</span>
          </p>
          <p class="info-box flex">
            <label>{{ $t('com.report.antiCheating.detailsLog') }}：</label>
          </p>
          <timeline v-if="recordData?.loginInfoList?.length > 0">
            <timeline-item v-for="(record, index) in recordData.loginInfoList || []" :key="index">
              <template #dot>
                <div class="outer-circle">
                  <div class="inner-circle" />
                </div>
              </template>
              <span class="text">
                {{ record }}
              </span>
              <span class="text">{{ $t('com.report.antiCheating.canlogsys') }}</span>
            </timeline-item>
          </timeline>
          <van-divider></van-divider>
        </div>
      </div>
      <!-- 候选人身份信息 -->
      <div v-if="idCardList.length" class="module-card">
        <h2 class="module-title left-icon">{{ $t('com.report.antiCheating.candidateID') }}</h2>
        <div class="idcard-container">
          <div class="cert">
            <img :src="idCardList[0]" @click="viewIdCard(0)" />
            <p class="label">{{ $t('com.report.antiCheating.candidateIDPositive') }}</p>
          </div>
          <div class="cert">
            <img :src="idCardList[1]" @click="viewIdCard(1)" />
            <p class="label">{{ $t('com.report.antiCheating.candidateIDNegative') }}</p>
          </div>
        </div>
        <van-divider></van-divider>
      </div>
      <!-- 全程录制视频 -->
      <div v-if="questionVideoList.length || cameraMonitorList.length" class="module-card">
        <h2 class="module-title left-icon">{{ $t('com.report.antiCheating.wholeProcessVideo') }}</h2>
        <div class="video-list">
          <!-- 新数据，新数据的全程录制视频放在 cameraMonitorList 字段里面 -->
          <div v-for="(item, index) in cameraMonitorList" :key="index" class="video-item">
            <video-player @play="onPlay" :playsinline="true" class="vjs-big-play-centered" :options="playerOptions(item)"></video-player>
          </div>
          <!-- 兼容旧数据，旧数据的全程录制视频放在 answerResult 字段里面 -->
          <!-- <div v-for="(vArrray, index) in questionVideoList" :key="'vArrray' + index">
            <div v-for="(videoItem, i) in vArrray" :key="i" class="video-item">
              <video-player @play="onPlay" :playsinline="true" class="vjs-big-play-centered" :options="playerOptions(videoItem)"></video-player>
            </div>
          </div> -->
        </div>
        <van-divider></van-divider>
      </div>
      <!-- 屏幕录制视频 -->
      <div v-if="fullRecords.length > 0" class="module-card">
        <h2 class="module-title left-icon">{{ $t('com.report.antiCheating.screenVideo') }}</h2>
        <div class="video-list">
          <p class="text">{{ $t('com.report.antiCheating.screenVideoTip') }}</p>
          <div v-for="(item, index) in fullRecords" :key="index" class="video-item">
            <div class="time-info flex">
              <label>{{ $t('com.report.antiCheating.recordTime') }}：</label>
              <span class="time">{{ moment(item.createdDate).format('YYYY-MM-DD HH:mm:ss') }}</span>
            </div>
            <video-player @play="onPlay" :playsinline="true" class="vjs-big-play-centered" :options="playerOptions(item)"></video-player>
          </div>
        </div>
        <van-divider></van-divider>
      </div>
      <!-- 双机位监控视频 -->
      <div v-if="phoneMonitorVideoUrl.length" class="module-card">
        <h2 class="module-title left-icon">{{ $t('com.report.antiCheating.doubleVideo') }}</h2>
        <div class="video-list">
          <div v-for="(url, index) in phoneMonitorVideoUrl" :key="index" class="video-item">
            <video-player @play="onPlay" :playsinline="true" class="vjs-big-play-centered" :options="playerOptions(url)"></video-player>
          </div>
        </div>
        <van-divider></van-divider>
      </div>
      <!-- 作答进度 -->
      <div v-if="antiCheatingLogs.length || progressImageList.length" class="module-card">
        <h2 class="module-title left-icon">
          <span>{{ $t('com.report.antiCheating.answerProgress') }}</span>
          <img src="@/assets/img/test.png" alt="test" class="test-icon" />
        </h2>
        <!-- 照片墙 -->
        <custom-image-viewer
          v-if="showProgressImageWall && progressImageList.length"
          display-mode="expand"
          :image-list="progressImageList"
          :multiple-image-list="multipleImageList"
          multiple-tab
          class="image-wall"
        />
        <div class="progress-container">
          <timeline class="log-list">
            <timeline-item v-for="(item, index) in antiCheatingLogs" :key="index">
              <template #dot>
                <div class="outer-circle">
                  <div class="inner-circle" />
                </div>
              </template>
              <span class="text">{{ formatDate(item.createdTime) }}</span>
              <div class="desc">
                <span v-show="item.content" class="text sub desc_content">{{ item.content }}</span>
                <span v-show="item.platform" class="text sub">|{{ getPlatform(item.platform) }}</span>
                <p class="text sub">{{ item.ip + (item.ipAddr ? `（${item.ipAddr}）` : '') }}</p>
              </div>
            </timeline-item>
          </timeline>
        </div>
      </div>
      <!-- 文本相似度dialog -->
      <van-dialog
        v-model="similarDialogShow"
        :title="$t('com.report.antiCheating.textSimilarity') + `（${$t(`com.report.antiCheating.${similarDetailType}`)}）`"
        :confirmButtonText="$t('com.report.antiCheating.close')"
        class="similar-dialog"
      >
        <div class="similar-list">
          <div v-for="(item, index) in currTextSimilarInfo.textSimilarityAnswers || []" :key="index" class="similar-item">
            <h4 class="title">
              <span>{{ $t('com.report.antiCheating.serialNumber') + (index + 1) }}：</span>
              <span class="percent">{{ similarDetailType == 'accurate' ? item.wordTextSimilarity : item.aiTextSimilarity }}%</span>
            </h4>
            <p class="content">{{ item.publicAnswer }}</p>
            <van-divider v-if="index !== currTextSimilarInfo.textSimilarityAnswers.length - 1" />
          </div>
        </div>
      </van-dialog>
    </div>
    <Empty v-else :title="$t('com.report.antiCheating.noContent')" />
  </div>
</template>

<script>
import Empty from '@/components/reportV3/common/empty.vue'
import Timeline from '@/components/timeline/timeline'
import TimelineItem from '@/components/timeline/timeline-item'
import CustomImageViewer from '@/components/reportV3/antiCheating/customImageViewer'
import { getCheatLogs } from '@/api/candidate'
import moment from 'moment'
import { ImagePreview } from 'vant'

export default {
  name: 'AntiCheating',
  components: {
    Empty,
    Timeline,
    TimelineItem,
    CustomImageViewer,
  },
  inject: {
    provideInfo: { value: 'provideInfo', default: null },
  },
  data() {
    return {
      moment,
      // 页面跳出
      recordData: {},
      // 文本相似度
      similarDialogShow: false, // 文本相似度dialog
      similarDetailType: 'accurate', // 查看的文本相似度类型，精确 or 模糊
      currTextSimilarInfo: {}, // 所选题目的文本相似度信息
    }
  },
  computed: {
    // 是否展示防作弊内容
    showContent() {
      return (
        this.idCardList?.length ||
        this.fullRecords?.length ||
        this.questionVideoList?.length ||
        this.cameraMonitorList?.length ||
        this.recordData?.cheatLogs?.length > 0 ||
        this.phoneMonitorVideoUrl?.length ||
        (this.antiCheatingInfo.abnormalDetection === 1 && this.recordData?.usedTimes > 0) ||
        this.antiCheatingInfo.candidateLoginLimitTimes > -1 ||
        this.antiCheatingInfo.checkTextRepetition === 1 ||
        this.antiCheatingInfo.checkPhotoDetection === 1 ||
        this.antiCheatingLogs?.length ||
        this.progressImageList?.length
      )
    },
    jobseekerChannelId() {
      return this.$store.state.jobseekerChannelId || this.$store.state.interviewResult.jobseekerChannelId
    },
    // 防作弊记录
    antiCheatingInfo() {
      return this.provideInfo?.antiCheatingInfo || {}
    },
    // 异常图片
    abnormalPictures() {
      return (this.antiCheatingInfo.abnormalPictures || []).map(item => ({
        url: item.url,
        date: item.createTime,
      }))
    },
    // 身份证信息
    idCardList() {
      const { frontIdCard = '', backIdCard = '' } = this.provideInfo?.idCard || {}
      if (frontIdCard || backIdCard) {
        return [frontIdCard, backIdCard]
      }
      return []
    },
    // 新数据的全程录制视频
    cameraMonitorList() {
      return this.provideInfo?.cameraMonitorList || []
    },
    // 旧数据的全程录制视频
    questionVideoList() {
      return this.provideInfo?.questionVideoList || []
    },
    // 屏幕录制视频
    fullRecords() {
      return this.provideInfo?.fullRecords || []
    },
    // 双机位监控视频
    phoneMonitorVideoUrl() {
      return this.$store.state.interviewResult?.mobileMonitorVideoList || []
    },
    // 防作弊日志
    antiCheatingLogs() {
      return this.provideInfo?.antiCheatingLogs || []
    },
    // 获取防作弊监控照片（电脑监控、手机监控、屏幕监控）
    antiCheatingImages() {
      return this.provideInfo?.antiCheatingImages || {}
    },
    // 作答进度-照片墙
    multipleImageList() {
      return [
        {
          // 电脑监控
          label: this.$t('com.report.antiCheating.computerMonitoring'),
          list: this.antiCheatingImages.fullRecordingImages || [],
          show: Boolean(this.antiCheatingInfo.fullRecordingFlag),
        },
        {
          // 手机监控
          label: this.$t('com.report.antiCheating.phoneMonitoring'),
          list: this.antiCheatingImages.mobileMonitorImages || [],
          show: Boolean(this.antiCheatingInfo.phoneMonitoring),
        },
        {
          // 屏幕监控
          label: this.$t('com.report.antiCheating.screenRecording'),
          list: this.antiCheatingImages.screenRecordingImages || [],
          show: Boolean(this.antiCheatingInfo.isFullrecording),
        },
      ]
    },
    // 作答进度-照片墙-全部照片
    progressImageList() {
      return this.multipleImageList.map(item => item.list).flat()
    },
    // 是否展示作答进度里的照片墙
    showProgressImageWall() {
      // 电脑监控: fullRecordingFlag
      // 手机监控: phoneMonitoring
      // 屏幕录制: isFullrecording
      const { fullRecordingFlag, phoneMonitoring, isFullrecording } = this.antiCheatingInfo
      return fullRecordingFlag || phoneMonitoring || isFullrecording
    },
    interviewId() {
      return this.$store.state.interviewId
    },
  },
  created() {
    this.getExitPageInfo() // 获取跳出页面数据
  },
  methods: {
    // 获取跳出页面数据
    async getExitPageInfo() {
      if (this.jobseekerChannelId) {
        // 获取跳出次数
        const res = await getCheatLogs({
          jobseekerChannelId: this.jobseekerChannelId,
          interviewId: this.interviewId,
        }).catch(() => ({}))
        const { code, msg, data } = res
        if (code === 0) {
          this.recordData = data || {}
        } else {
          this.$toast({ type: 'fail', message: msg })
        }
      }
    },
    // 查看相似度详情
    viewSimilarDetail(type, obj = {}) {
      this.currTextSimilarInfo = obj || {}
      this.similarDetailType = type
      this.similarDialogShow = true
    },
    // 查看身份证
    viewIdCard(index = 0) {
      ImagePreview({
        images: this.idCardList,
        startPosition: index,
        showIndicators: true,
        closeable: true,
      })
    },
    // 播放器播放事件
    onPlay() {
      const videoArray = document.getElementsByTagName('video')
      if (videoArray && videoArray.length) {
        for (let i = 0; i < videoArray.length; i++) {
          videoArray[i].onplay = () => {
            for (let j = 0; j < videoArray.length; j++) {
              if (i !== j) {
                videoArray[j].pause()
              }
            }
          }
        }
      }
    },
    // 播放器选项
    playerOptions(videoItem) {
      return {
        playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
        autoplay: false, // 如果为true,浏览器准备好时开始回放。
        muted: true, // 默认情况下将会消除任何音频。
        loop: false, // 是否视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: 'video/mp4', // 类型
            src: videoItem.videoUrlMp4 || videoItem.videoUrl || videoItem.video_url_mp4 || videoItem.video_url || videoItem.videoScreenshotUrl, // url地址
          },
        ],
        poster: videoItem['video_screenshot_url'] || videoItem['videoScreenshotUrl'] || videoItem['screenShot'] || '', // 封面地址
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, // 当前时间和持续时间的分隔符
          durationDisplay: true, // 显示持续时间
          remainingTimeDisplay: false, // 是否显示剩余时间功能
          fullscreenToggle: true, // 是否显示全屏按钮
        },
      }
    },
    // 提取题目的文字
    getQuestionText(innerText) {
      if (innerText) {
        const parser = document.createElement('div')
        parser.innerHTML = innerText
        innerText = parser.innerText
      }
      return innerText
    },
    // 格式化时间
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD HH:mm:ss')
    },
    // 获取设备类型
    getPlatform(platform) {
      // 1-pc 2-小程序 3-h5
      const platformMap = {
        1: 'PC',
        2: this.$t('com.report.antiCheating.miniProgram'),
        3: 'H5',
      }
      return platformMap[platform] || ''
    },
  },
}
</script>

<style lang="scss" scoped>
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}
.flex {
  display: flex;
}
:deep(.van-dialog) {
  .van-dialog__content {
    padding: 20px 24px 10px;
  }
  &.similar-dialog {
    .similar-list {
      max-height: 50vh;
      overflow: auto;
      .similar-item {
        .title {
          font-size: 16px;
          font-weight: 600;
          color: #333333;
          line-height: 22px;
          margin-bottom: 8px;
          .percent {
            color: #1890ff;
          }
        }
        .content {
          font-size: 14px;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
        }
        .van-divider {
          margin: 12px 0;
        }
      }
    }
  }
}
// 时间轴
:deep(.timeline-item) {
  .dot {
    transform: translateY(3px);
    .line {
      transform: translateX(7px);
    }
  }
  .desc {
    // background: #b9ddff;
    width: 300px;
    // word-break: break-all;
    word-wrap: break-word;
    margin-bottom: 5px;
    .desc_content {
      // background: #ffc118;
    }
    .text {
      font-size: 15px;
      font-weight: 400;
      color: #171717;
      line-height: 21px;
      margin-bottom: 0;
      &.sub {
        font-size: 14px;
        font-weight: 400;
        color: #6d7278;
        &:not(:last-child) {
          font-size: 13px;
        }
      }
    }
  }
  .outer-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 14px;
    background: #b9ddff;
    border-radius: 50%;
    .inner-circle {
      width: 6px;
      height: 6px;
      background: #1890ff;
      border-radius: 50%;
    }
  }
}
.anti-cheating {
  margin-top: 20px;
  padding: 0 4px;
  * {
    box-sizing: border-box;
  }
  .outer-card {
    position: relative;
    background: #f2f9ff;
    padding: 0 12px 19px;
    margin-bottom: 20px;
    &::before {
      content: '';
      position: absolute;
      border-right: 5px solid #005aae;
      border-top: 5px solid transparent;
      border-bottom: 5px solid #005aae;
      border-left: 5px solid transparent;
      left: 0;
      top: -10px;
    }
    &-title {
      align-items: center;
      margin-bottom: 14px;
      .label {
        height: 38px;
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        line-height: 22px;
        align-items: center;
        justify-content: center;
        background: #1890ff;
        border-radius: 0px 0px 2px 2px;
        padding: 0 9px;
        margin-left: -2px;
        margin-top: -10px;
        z-index: 1;
      }
      .level {
        font-size: 22px;
        font-weight: 500;
        color: #ff5420;
        line-height: 28px;
        margin-left: 8px;
      }
    }
    .inner-card {
      margin-bottom: 2px;
      flex-wrap: wrap;
      &-item {
        width: calc(50% - 5px);
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid rgba(24, 144, 255, 0.25);
        padding: 8px 12px;
        margin: 0 10px 10px 0;
        &:nth-child(2n) {
          margin-right: 0;
        }
        .card-title {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
          color: #333333;
          &.color {
            color: #1890ff;
          }
        }
        .line {
          margin-bottom: 5px;
          font-size: 14px;
          line-height: 16px;
          .card-label {
            font-weight: 400;
          }
          .card-percent {
            font-weight: 500;
          }
        }
        .card-count {
          font-size: 26px;
          font-weight: 600;
          color: #333333;
          line-height: 30px;
        }
        .unit {
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
          margin-left: 3px;
        }
      }
    }
    .tip-text {
      font-size: 12px;
      font-weight: 400;
      color: #afb6ca;
      line-height: 17px;
    }
  }
  .module-card {
    margin-bottom: 20px;
    .module-title {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      line-height: 22px;
      margin-bottom: 16px;
      &.left-icon {
        position: relative;
        padding-left: 9px;
        &::before {
          content: '';
          position: absolute;
          width: 4px;
          height: 18px;
          background: #1890ff;
          border-radius: 2px;
          left: 0;
          top: 2px;
        }
      }
      .test-icon {
        height: 15px;
        margin-left: 5px;
        vertical-align: -3px;
      }
    }
    // 文本相似度
    .problem-list {
      &-item {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        margin-bottom: 12px;
        .text {
          margin-bottom: 12px;
        }
        .answer-card {
          background: rgba(24, 144, 255, 0.05);
          border-radius: 4px;
          border: 1px dashed #1890ff;
          padding: 9px 12px 12px;
          color: #666666;
          margin-bottom: 12px;
          .answer-title {
            font-weight: 600;
            color: #333333;
            margin-bottom: 3px;
          }
        }
        .similar-card {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 20px;
          padding: 14px 12px;
          justify-content: space-between;
          align-items: center;
          border-radius: 3px;
          border: 1px solid #dddddd;
          margin-bottom: 8px;
          .percent {
            font-weight: 600;
          }
          .btn {
            color: #1890ff;
            cursor: pointer;
          }
        }
      }
    }
    // 异常照片
    .images-container {
      .text {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
        margin-bottom: 12px;
      }
    }
    // 跳出次数
    .page-exit-container {
      .info-box {
        align-items: center;
        margin-bottom: 10px;
        label {
          font-size: 14px;
          font-weight: 400;
          color: #6d7278;
          line-height: 20px;
        }
        .info {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 28px;
        }
      }
    }
    // 身份信息
    .idcard-container {
      padding: 0 28px;
      .cert {
        background: #f8f8fa;
        border-radius: 5px;
        border: 1px solid #d6dde8;
        margin-bottom: 16px;
        padding: 36px 80px 28px;
        img {
          display: inline-block;
          width: 170px;
          height: 100px;
          margin-bottom: 13px;
          object-fit: contain;
        }
        .label {
          font-size: 16px;
          font-weight: 400;
          color: #627098;
          line-height: 22px;
          text-align: center;
        }
      }
    }
    // 视频列表
    .video-list {
      .text {
        font-size: 14px;
        font-weight: 400;
        color: #6d7278;
        line-height: 20px;
        margin-bottom: 8px;
      }
      .video-item {
        margin-bottom: 12px;
        .time-info {
          margin-bottom: 5px;
          align-items: center;
          label {
            font-size: 14px;
            font-weight: 400;
            color: #6d7278;
            line-height: 20px;
          }
          .time {
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            line-height: 28px;
          }
        }
      }
    }
    // 作答进度
    .image-wall {
      margin-bottom: 10px;
    }
    .progress-container {
      .log-list {
        margin-bottom: 12px;
      }
    }
  }
}
</style>

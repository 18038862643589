import { render, staticRenderFns } from "./online-test.vue?vue&type=template&id=e8179c4a&scoped=true"
import script from "./online-test.vue?vue&type=script&lang=js"
export * from "./online-test.vue?vue&type=script&lang=js"
import style0 from "./online-test.vue?vue&type=style&index=0&id=e8179c4a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8179c4a",
  null
  
)

export default component.exports
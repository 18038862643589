<template>
  <div class="id-collect">
    <div class="id-collect_tip">请填写您的身份信息<Br />以便我们验证您的身份</div>
    <van-form @submit="onSubmit" ref="vanform">
      <van-field v-model="name" :rules="[{ required: true, message: '请填写姓名' }]" label-width="2rem" name="姓名" label="姓名" placeholder="请输入姓名" maxlength="50" show-word-limit></van-field>
      <van-field v-model="idNo" :rules="rulesId" label-width="2rem" name="身份证号码" label="身份证号码" placeholder="请输入身份证号码"></van-field>
      <van-button :loading="loading" round block type="info" native-type="submit" size="normal" class="submit-btn">下一步</van-button>
    </van-form>
  </div>
</template>
<script>
import { tencentFaceStartLogin } from '@/api/candidate'
export default {
  data() {
    return {
      idNo: '',
      name: '',
      rulesId: [
        {
          required: true,
          message: '请填写身份证号码',
          trigger: 'onBlur/onChange',
        },
        {
          validator: value => {
            const reg = /^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/
            console.log(value)
            if (reg.test(value)) return true
            return false
          },
          message: '请输入正确的身份证号码',
          trigger: 'onBlur/onChange',
        },
      ],
      loading: false,
    }
  },
  computed: {
    callBackUrl() {
      const { redirectUrl, jobseekerChannelId, interviewId } = this.$route.query
      const origin = location.origin
      const url = `${origin}/idcheck?redirectUrl=${encodeURIComponent(redirectUrl)}&jobseekerChannelId=${jobseekerChannelId}&interviewId=${interviewId}&idNo=${this.idNo}&name=${this.name}`
      return url
    },
  },
  mounted() {
    document.title = '身份验证'
    this.initQuery()
  },
  methods: {
    initQuery() {
      const { idNo, name } = this.$route.query
      this.idNo = idNo
      this.name = name ? decodeURIComponent(name) : ''
    },
    onSubmit() {
      this.loading = true
      this.$refs.vanform
        .validate()
        .then(() => {
          this.tencentFaceStartLogin()
        })
        .catch(() => {
          this.loading = false
        })
    },
    tencentFaceStartLogin() {
      const isMobile = /(mobile|nokia|iphone|ipad|android|samsung|htc|blackberry)/.test(navigator.userAgent.toLowerCase())
      console.log('tencentFaceStartLogin')
      const data = {
        userName: this.name,
        idCard: this.idNo,
        jobseekerChannelId: this.$route.query?.jobseekerChannelId,
        platform: isMobile ? 'h5' : 'pc',
        returnUrl: this.callBackUrl,
        // redirectUrl: this.callBackUrl,
        // jobseekerChannelId: this.$route.query?.jobseekerChannelId,
        // interviewId: this.$route.query?.interviewId,
        // idNo: this.idNo,
        // name: this.name,
        // type: isMobile ? 'mobile' : 'pc',
      }
      console.log('tencentFaceStartLogin', data)
      tencentFaceStartLogin(data).then(res => {
        this.loading = false
        if (res && res.code === 0) {
          if (res.data.state && +res.data.state === 10) {
            location.href = this.$route.query.redirectUrl
          } else {
            location.href = res.data.loginUrl
          }
          // location.href = res.data.faceUrl
        } else {
          this.$notify({ type: 'danger', message: res.msg })
        }
      })
    },
  },
}
</script>
<style lang="scss">
.id-collect {
  max-width: 520px;
  margin: auto;
  // margin-top: 20px;
  .id-collect_tip {
    font-size: 18px;
    padding: 24px 20px 52px 20px;

    background: linear-gradient(270deg, #47b6ff 0%, #227fff 100%);
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }
  .van-form {
    margin-top: -20px;
    background: #ffffff;
    padding-top: 20px;
    border-radius: 12px 12px 0px 0px;
    .van-cell {
      margin-bottom: 20px;
      .van-cell__value {
        .van-field__body {
          padding-right: 35px;
        }
        .van-field__word-limit {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
    .van-button {
      width: 90%;
      margin: auto;
    }
  }
}
</style>
